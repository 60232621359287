import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
  Table,
  Row,
  Col,
  Button,
  Dropdown,
  Menu,
  Icon,
  message,
  Spin,
} from 'antd';
import { Link } from 'react-router-dom';

import { actions } from './courses.redux';
import './course-detail.page.css';
import AddUserModal from './add-user.modal';
import DisableUserModal from './disable-user.modal';
import ImportCourseUsersModal from './import-course-users.modal';
import BaseService from '../core/services/baseService';

const decimalFormat = new Intl.NumberFormat('es-CO', {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

const positionTableCols = [
  {
    title: 'Nombre',
    dataIndex: 'name',
  },
  {
    title: 'Area',
    dataIndex: 'area',
    width: 90,
  },
  {
    title: 'Puntos',
    dataIndex: 'points',
    width: 70,
    align: 'right',
    render: (val) => decimalFormat.format(val),
  },
];

const areasTableCols = [
  {
    title: 'Area',
    dataIndex: 'area',
  },
  {
    title: 'Promedio',
    dataIndex: 'points',
    width: 90,
    align: 'right',
    render: (val) => decimalFormat.format(val),
  },
];

class CourseDetailPage extends PureComponent {
  state = {
    userModalVisible: false,
    importModalVisible: false,
    disableModalVisible: false,
    fetchingCertificates: false,
  };

  canEdit = () => {
    return this.props.auth.user.role === 'admin';
  };

  queryCourse = () => {
    const courseId = this.props.match.params.id;
    this.props.get(courseId);
  };

  componentDidMount() {
    this.queryCourse();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.course) {
      this.props.layout.changeTitle(nextProps.course.name);
    }

    if (this.props.saving && !nextProps.saving) {
      if (nextProps.error) {
        alert(nextProps.error);
      } else {
        if (this.state.userModalVisible) {
          this.hideAddUser();
          this.queryCourse();
        } else if (this.state.importModalVisible) {
          this.hideImportModal();
          this.queryCourse();
        } else if (this.state.disableModalVisible) {
          this.hideDisableUser();
          this.queryCourse();
        }
      }
    }
  }

  openAddUser = () => {
    this.setState({ userModalVisible: true });
  };

  hideAddUser = () => {
    this.setState({ userModalVisible: false });
  };

  handleAddUsers = (users) => {
    this.props.addusers(this.props.course.id, users);
  };

  openDisableUser = () => {
    this.setState({ disableModalVisible: true });
  };

  hideDisableUser = () => {
    this.setState({ disableModalVisible: false });
  };

  handleDisableUsers = (users) => {
    this.props.disableusers(this.props.course.id, users);
  };

  downloadCertificates = async () => {
    const { course } = this.props;
    try {
      this.setState({ fetchingCertificates: true });
      var blob = await BaseService.download(
        `/responses/${course.id}/certificates`
      );

      var windowUrl = window.URL || window.webkitURL;
      var url = windowUrl.createObjectURL(blob);
      var anchor = document.createElement('a');
      anchor.href = url;
      anchor.download = `${course.name}.zip`;
      anchor.click();
      if (anchor.parentNode) anchor.parentNode.removeChild(anchor);
      windowUrl.revokeObjectURL(url);
    } catch (err) {
      console.error(err);
      message.error(err.message);
    } finally {
      this.setState({ fetchingCertificates: false });
    }
  };

  handleAction = async (action) => {
    console.warn(`Action ${action}`);

    switch (action) {
      case 'import':
        this.openImport();
        break;
      case 'disable':
        this.openDisableUser();
        break;
      case 'certificates':
        await this.downloadCertificates();
        break;
      default:
        console.warn('unknown action');
    }
  };

  getCheckboxProps = (record) => {
    return {
      disabled: !record.enabled,
    };
  };

  render() {
    const { course } = this.props;
    const { fetchingCertificates } = this.state;
    if (!course) return <div />;

    return (
      <div className="content-internal">
        <Spin tip="Generando certificados ..." spinning={fetchingCertificates}>
          <div style={{ textAlign: 'right' }}>
            <Button.Group>
              <Link target="_blank" to={`/courses/${course.uuid}/scoretable`}>
                <Button>Top 10 Tiempo Real</Button>
              </Link>
              {this.canEdit() && (
                <Button type="primary" onClick={this.openAddUser}>
                  Asignar Usuarios
                </Button>
              )}
              <Dropdown
                overlay={
                  <Menu onClick={({ key }) => this.handleAction(key)}>
                    {this.canEdit() && (
                      <Menu.Item key="disable">
                        <Icon type="usergroup-delete" /> Desasignar usuarios
                      </Menu.Item>
                    )}
                    {this.canEdit() && (
                      <Menu.Item key="import">
                        <Icon type="upload" /> Importar usuarios
                      </Menu.Item>
                    )}
                    <Menu.Item key="certificates">
                      <Icon type="download" /> Descargar certificados
                    </Menu.Item>
                  </Menu>
                }
              >
                <Button type="primary" icon="down"></Button>
              </Dropdown>
            </Button.Group>
          </div>
          <div>
            <Row gutter={10}>
              <Col xs={24} sm={12} md={12} lg={8}>
                <h3>
                  Completados (
                  {decimalFormat.format(
                    (course.report?.users?.completed ?? []).length
                  )}
                  )
                </h3>
                <Table
                  loading={this.props.fetching}
                  className="tbl_report"
                  size="small"
                  scroll={{ y: 'max-content' }}
                  rowKey={(u) => u.id}
                  columns={positionTableCols}
                  pagination={false}
                  rowClassName={(record, index) =>
                    record.disabled ? 'disabled' : ''
                  }
                  dataSource={course.report?.users?.completed ?? []}
                  locale={{
                    emptyText: 'Ningun usuario ha completado el curso',
                  }}
                />
              </Col>
              <Col xs={24} sm={12} md={12} lg={8}>
                <h3>
                  Activos (
                  {decimalFormat.format(
                    (course.report?.users?.active ?? []).length
                  )}
                  )
                </h3>
                <Table
                  loading={this.props.fetching}
                  className="tbl_report"
                  size="small"
                  scroll={{ y: 240 }}
                  rowKey={(u) => u.id}
                  columns={positionTableCols}
                  pagination={false}
                  rowClassName={(record, index) =>
                    record.disabled ? 'disabled' : ''
                  }
                  dataSource={course.report?.users?.active ?? []}
                  locale={{ emptyText: 'No hay usuarios activos el curso' }}
                />
              </Col>
              <Col xs={24} sm={12} md={12} lg={8}>
                <h3>
                  Sin avance (
                  {decimalFormat.format(
                    (course.report?.users?.noProgress ?? []).length
                  )}
                  )
                </h3>
                <Table
                  loading={this.props.fetching}
                  className="tbl_report"
                  size="small"
                  scroll={{ y: 240 }}
                  rowKey={(u) => u.id}
                  columns={positionTableCols}
                  pagination={false}
                  rowClassName={(record, index) =>
                    record.disabled ? 'disabled' : ''
                  }
                  dataSource={course.report?.users?.noProgress ?? []}
                  locale={{
                    emptyText: 'No hay usuarios sin avance en el curso',
                  }}
                />
              </Col>
              <Col xs={24} sm={12} md={12} lg={8}>
                <h3>
                  No descargada (
                  {decimalFormat.format(
                    (course.report?.users?.notDownloaded ?? []).length
                  )}
                  )
                </h3>
                <Table
                  loading={this.props.fetching}
                  className="tbl_report"
                  size="small"
                  scroll={{ y: 240 }}
                  rowKey={(u) => u.id}
                  columns={positionTableCols}
                  pagination={false}
                  rowClassName={(record, index) =>
                    record.disabled ? 'disabled' : ''
                  }
                  dataSource={course.report?.users?.notDownloaded ?? []}
                  locale={{
                    emptyText: 'No hay usuarios sin descargar la aplicación',
                  }}
                />
              </Col>
              <Col xs={24} sm={12} md={12} lg={8}>
                <h3>Areas</h3>
                <Table
                  loading={this.props.fetching}
                  className="tbl_report"
                  size="small"
                  scroll={{ y: 240 }}
                  rowKey={(u) => u.id}
                  columns={areasTableCols}
                  pagination={false}
                  dataSource={course.report?.areas ?? []}
                  locale={{ emptyText: 'No hay información en el momento' }}
                />
              </Col>
            </Row>
          </div>
        </Spin>

        <AddUserModal
          visible={this.state.userModalVisible}
          onCancel={this.hideAddUser}
          confirmLoading={this.state.saving}
          onOk={this.handleAddUsers}
          courseId={course.id}
        />
        <DisableUserModal
          visible={this.state.disableModalVisible}
          onCancel={this.hideDisableUser}
          confirmLoading={this.state.saving}
          onOk={this.handleDisableUsers}
          courseId={course.id}
        />
        <ImportCourseUsersModal
          visible={this.state.importModalVisible}
          confirmLoading={this.props.saving}
          onCancel={this.hideImportModal}
          onOk={this.handleImport}
        />
      </div>
    );
  }

  openImport = () => {
    this.setState({ importModalVisible: true });
  };

  handleImport = (data) => {
    this.props.import(this.props.course.id, data);
  };

  hideImportModal = () => {
    this.setState({ importModalVisible: false });
  };
}

function mapStateToProps(state) {
  return { ...state.courses, auth: state.auth };
}

function mapDispatchToProps(dispatch) {
  return {
    get: (courseId) => dispatch(actions.get(courseId)),
    addusers: (courseId, userIds) =>
      dispatch(actions.assignusers(courseId, userIds)),
    disableusers: (courseId, userIds) =>
      dispatch(actions.disableusers(courseId, userIds)),
    import: (courseId, data) => dispatch(actions.import(courseId, data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CourseDetailPage);
